.footer-container{
    position: relative;
    justify-content: center;
}
.footer-container>hr{
    border: 1px solid var(--lightgray);
}
.footer{
    padding: 1rem 2rem;
    display: flex;
    flex-direction: column;
    gap: 4rem;
    align-items: center;
    justify-content: center;
    height: 5rem;
}
.social-links{
    display: flex;
    gap: 4rem;
}
.social-links>img{
    width: 2rem;
    height: 2rem;
    cursor: pointer;
}
.logo-footer>img{
   width: 10rem;
}
.logo-footer{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 50px;
}
.footer-blur1{
    bottom: 0;right: 22%;
    width: 26rem;
    height: 12rem;
    filter: blur(150px);
    background: rgb(255, 115, 0);
}
.footer-blur2{
    bottom: 0;left: 22%;
    width: 26rem;
    height: 12rem;
    filter: blur(150px);
    background: rgb(255, 115, 0);
}